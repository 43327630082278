import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import VueMarqueeSlider from 'vue-marquee-slider';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/common.scss'
import '@/assets/style/normalize.css'

Vue.use(ElementUI)
Vue.use(VueMarqueeSlider)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
