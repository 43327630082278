import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/home.vue')
    },
  ]
}]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { y: 0 }
  }
})

export default router
