<template>
  <div class="layout-index">
    <!-- <header-component></header-component> -->
    <router-view class="main-router-view"></router-view>
  </div>
</template>
<script>
// import headerComponent from '@/components/header.vue'
export default {
  components: {
    // headerComponent
  }
}
</script>
<style lang="scss" scoped>
.layout-index{
}
</style>
